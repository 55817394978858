import React from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import HomePage from 'pages/Home';

import { Routes, Route } from 'react-router-dom';
import GalleryPage from 'pages/Gallery';

const App = () => {
  return (
    <div className='min-h-full'>
      <Header />
      <main>
        <div className='max-w-8xl mx-auto space-y-2'>
          <Routes>
            <Route path='gallery' element={<GalleryPage />} />
            <Route path='about' element={<HomePage />} />
            <Route path='contact' element={<HomePage />} />
            <Route path='*' element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default App;
