/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, PhoneIcon, XIcon } from '@heroicons/react/outline';

import { Outlet, Link } from 'react-router-dom';

import Logo from '../assets/images/logo.jpeg';

const rooms = [
  {
    name: 'Presidental Suite',
    description: '360-degree views',
    href: '#',
  },
  {
    name: 'Panoramic Suite',
    description: 'Nature Hunt',
    href: '#',
  },
  {
    name: 'Mountain Lodge View',
    description: 'Amazing Viewing Experience',
    href: '#',
  },
  {
    name: 'Infinity Lake View',
    description: 'Keep the beauty closer',
    href: '#',
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  return (
    <Popover className='relative bg-white'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6'>
        <div className='flex justify-between items-center py-6 md:justify-start md:space-x-10'>
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <a href='#'>
              <span className='sr-only'>Workflow</span>
              <img className='h-8 w-auto sm:h-10' src={Logo} alt='' />
            </a>
          </div>
          <div className='-mr-2 -my-2 md:hidden'>
            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>
          <Popover.Group as='nav' className='hidden md:flex space-x-10'>
            <Link to={`/`} className='text-base font-medium text-gray-500 hover:text-gray-900'>
              Home
            </Link>

            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none '
                    )}>
                    <span>Rooms</span>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'>
                    <Popover.Panel className='absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'>
                      <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                        <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                          {rooms.map((item: any) => (
                            <a key={item.name} href={item.href} className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'>
                              {item.icon && <item.icon className='flex-shrink-0 h-6 w-6 text-indigo-600' aria-hidden='true' />}
                              <div className='ml-4'>
                                <p className='text-base font-medium text-gray-900'>{item.name}</p>
                                <p className='mt-1 text-sm text-gray-500'>{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link to={`/gallery`} className='text-base font-medium text-gray-500 hover:text-gray-900'>
              Gallery
            </Link>
            <Link to={`/about`} className='text-base font-medium text-gray-500 hover:text-gray-900'>
              About
            </Link>
            <Link to={`/contact`} className='text-base font-medium text-gray-500 hover:text-gray-900'>
              Contact
            </Link>
          </Popover.Group>
          <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
            <a href='tel:+923004900290' className='whitespace-nowrap text-base font-medium text-gray-500 hover:text-blue-900'>
              <PhoneIcon className='h-6 w-6' aria-hidden='true' />
            </a>
            <a
              href='#'
              className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'>
              Our Rates
            </a>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter='duration-200 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-100 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'>
        <Popover.Panel focus className='absolute top-0 inset-x-0 transition transform origin-top-right md:hidden z-30'>
          <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
            <div className='pt-5 pb-6 px-5'>
              <div className='flex items-center justify-between'>
                <div>
                  <img className='h-8 w-auto' src={Logo} alt='Workflow' />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='mt-6'>
                <nav className='grid gap-y-8'>
                  <a href='' className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'>
                    <span className='ml-3 text-base font-medium text-gray-900'>Home</span>
                  </a>
                  <a href='' className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'>
                    <span className='ml-3 text-base font-medium text-gray-900'>Gallery</span>
                  </a>
                  <a href='' className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'>
                    <span className='ml-3 text-base font-medium text-gray-900'>Rooms</span>
                  </a>
                  <a href='' className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50'>
                    <span className='ml-3 text-base font-medium text-gray-900'>About</span>
                  </a>
                </nav>
              </div>
            </div>
            <div className='py-6 px-5 space-y-6'>
              <div>
                <a
                  href='#'
                  className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'>
                  Pricing
                </a>
                <p className='mt-6 text-center text-base font-medium text-gray-500'>
                  Have any queries?{' '}
                  <a href='tel:+923004900290' className='text-indigo-600 hover:text-indigo-500'>
                    Call
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
