import Screen from 'components/Screen';
import React from 'react';

const GalleryPage = () => {
  return (
    <Screen>
      <div className='flex justify-center items-center py-12'>
        <div className='h-56 w-56'>
          <img
            className='w-full h-full object-fit rounded-full'
            src='https://static.wixstatic.com/media/fd5743_a51ac7891da946f992f42738592b48d2~mv2_d_6898_4898_s_4_2.jpg/v1/crop/x_985,y_116,w_4579,h_4260/fill/w_366,h_336,al_c,q_80,usm_0.66_1.00_0.01/DSC_1396-HDR.webp'
            alt=''
          />
        </div>
        <div className='pl-12 space-y-6 w-3/5 text-sm text-gray-800'>
          <h2 className='font-bold text-2xl text-gray-600'>Kumrat Valley | Mountain Lodge Resort</h2>
          <p>
            Ultra luxury resort in Pakistan. Tag your own Luxus Kumrat photos <br /> @mountainlodge to share on our page
          </p>
          <p>Mountain Lodge</p>
          <p>You can also use #mountainlodge to feature your photo in our gallery.</p>
        </div>
      </div>
    </Screen>
  );
};

export default GalleryPage;
