import React from 'react';

interface PropsIF {
  data: { title: string; caption: string; src: string };
}

const Cover: React.FC<PropsIF> = (props) => {
  const { title, caption, src } = props.data;
  return (
    <div className='relative w-full h-64 sm:h-screen'>
      <div className='absolute flex w-full h-full justify-center items-center'>
        <div className='z-10 text-white text-center filter drop-shadow-md border-2 px-2 py-1 sm:px-4 sm:py-2 md:px-12 rounded-lg cursor-default'>
          <h2 className='sm:text-4xl font-bold'>{title}</h2>
          <h3 className='text-xs sm:text-lg tracking-widest'>{caption}</h3>
        </div>
      </div>
      <img className='w-full h-full object-cover filter brightness-75' src={src} alt={title} />
    </div>
  );
};

export default Cover;
