import React from 'react';

import Cover from 'components/Cover';
import Section from 'components/Section';

import LandingImage from '../assets/images/lading.jpeg';

const landing = {
  title: 'KUMRAT VALLEY',
  caption: 'Mountain Lodge Resort',
  src: LandingImage,
};

const rooms = [
  {
    title: 'Presidential Suite',
    caption: '360 View Experience',
    src: 'https://static.wixstatic.com/media/fd5743_e461de392192444b83e32b9a025f5df0~mv2.jpg/v1/fill/w_2880,h_1200,al_c,q_90,usm_0.66_1.00_0.01/fd5743_e461de392192444b83e32b9a025f5df0~mv2.webp',
  },
  {
    title: 'Panoramic Suite',
    caption: 'Amazing Experience',
    src: 'https://static.wixstatic.com/media/fd5743_9d9306942bf348e487b5ed9d54231b8a~mv2_d_6937_4630_s_4_2.jpg/v1/fill/w_2880,h_1204,al_c,q_90,usm_0.66_1.00_0.01/fd5743_9d9306942bf348e487b5ed9d54231b8a~mv2_d_6937_4630_s_4_2.webp',
  },
];

const HomePage = () => {
  return (
    <div>
      <Cover data={landing} />
      <Section />
      {rooms.map((item, key) => {
        return <Cover key={key} data={item} />;
      })}
    </div>
  );
};

export default HomePage;
