import React from 'react';

const Section = () => {
  return (
    <div className='w-full h-72 flex justify-center items-center'>
      <div className='text-center w-4/5 sm:1/2 text-gray-600'>
        <h2 className='mb-6 font-bold text-lg sm:text-2xl'>DISCOVER VILLAS</h2>
        <p className='text-xs sm:text-sm text-justify'>
          At Mountain Lodge we offer you a unique journey, creating with you memorable experiences of stay, relaxation and rejuvenation in Kumrat Valley. Inspired by the endless blue colors of the sky
          and the lake, Luxus Hunza combines high-quality services that go beyond the standards for a truly luxurious hospitality experience.
        </p>
      </div>
    </div>
  );
};

export default Section;
